<template>
  <div class="px-4 py-2">
    <a-tabs :activeKey="activeKey" @change="change">
      <a-tab-pane key="1" tab="关于我们" force-render>
        <div class="w-full ">
          <div class="flex w-full py-3 pb-6 flex-end" style="width:100%">
            <a-button type="primary" @click="goEdit">
              编辑
            </a-button>
          </div>
          <template v-if="about">
            <div v-html="about" class="editor-content-view "></div>
          </template>
          <div v-else class="text-sm text-gray-400">
            暂未设置关于我们相关内容
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="使用说明">
        <div class="flex w-full py-3 pb-6 flex-end" style="width:100%">
          <a-button type="primary" @click="goEdit">
            编辑
          </a-button>
        </div>
        <div>
          <template v-if="use">
            <div v-html="use" class="editor-content-view "></div>
          </template>
          <div v-else class="text-sm text-gray-400">
            暂未设置使用说明相关内容
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { gettingInfo, settingInfo } from "@/api/system";
export default {
  data() {
    return {
      activeKey: "1",
      use: "",
      about: "",
    };
  },
  created() {
    this.gettingInfoFunc();
  },
  methods: {
    change(e) {
      this.activeKey = e;
      this.gettingInfoFunc();
    },
    gettingInfoFunc() {
      gettingInfo({ type: this.activeKey }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.activeKey == 1 ? (this.about = res.data) : (this.use = res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    goEdit() {
      this.$router.push({ path: `/contentEdit?type=${this.activeKey}` });
    },
  },
};
</script>
<style>
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}
</style>
