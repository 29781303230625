import request from "@/utils/request";

const systemApi = {
  settingInfo: "/admin/AdminSetting/settingInfo", // 关于我们、使用说明
};
/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function gettingInfo(parameter) {
  return request({
    url: systemApi.settingInfo,
    method: "get",
    params: parameter,
  });
}

export function settingInfo(parameter) {
  return request({
    url: systemApi.settingInfo,
    method: "post",
    params: parameter,
  });
}
